<template>
  <div class="container page">
    <div class="header">
      <van-nav-bar :title="$t('my.online_service')" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()"/>
        </template>
      </van-nav-bar>
    </div>
    <div class="servicelistItem">
        <div class="servicelistItemTop">
          <img class="servicelistItemImage" src="img/mine/kefu.png">
          <div class="servicelistItemText">{{this.$store.getters.getBaseInfo.name !==undefined ?this.$store.getters.getBaseInfo.name:this.$t("my.title")}}</div>
          <div class="servicelistItemBtn" @click="toServicePage()">
            <div class="servicelistItemBtnText">
              {{$t("my.contact")}}
            </div>
          </div>
        </div>
        <div class="servicelistItemBottom">
        <div class="servicelistItemInfoText">
          {{$t("my.service_time")}}
        </div>
      </div>
    </div>
			<div class="servicelistItem" style="display: none;">
			<div class="servicelistItemTop">
			<img class="servicelistItemImage" src="img/mine/line.png">
			<div class="servicelistItemText">LINE</div>
			<div class="servicelistItemBtn" @click="toServicePage_LINE()">
			<div class="servicelistItemBtnText">
			{{$t("my.contact")}}
			</div>
			</div>
			</div>
			<div class="servicelistItemBottom">
			<div class="servicelistItemInfoText">
			{{$t("my.service_time")}}
			</div>
			</div>
			</div>
			<div class="servicelistItem" style="display: none;">
			<div class="servicelistItemTop">
			<img class="servicelistItemImage" src="img/mine/WhatsApp.jpeg">
			<div class="servicelistItemText">WhatApp</div>
			<div class="servicelistItemBtn" @click="toServicePage_WhatsApp()">
			<div class="servicelistItemBtnText">
			{{$t("my.contact")}}
			</div>
			</div>
			</div>
			<div class="servicelistItemBottom">
			<div class="servicelistItemInfoText">
			{{$t("my.service_time")}}
			</div>
			</div>
			</div>
			<div class="servicelistItem" style="display: none;">
			<div class="servicelistItemTop">
			<img class="servicelistItemImage" src="img/mine/telegram.jpeg">
			<div class="servicelistItemText">Telegram</div>
			<div class="servicelistItemBtn" @click="toServicePage_Telegram()">
			<div class="servicelistItemBtnText">
			{{$t("my.contact")}}
			</div>
			</div>
			</div>
			<div class="servicelistItemBottom">
			<div class="servicelistItemInfoText">
			{{$t("my.service_time")}}
			</div>
			</div>
			</div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    toServicePage(){
      const service = this.$store.getters.getBaseInfo;
      console.log(service)
      if (service.iskefu == 1){
        console.log('ssss')
        window.location.href = service.kefu
      }
      // this.$router.push("ServicePage");
    },
	toServicePage_LINE(){
		const service = this.$store.getters.getBaseInfo;
		console.log(service)
		if (service.iskefu == 1){
		console.log('ssss')
		window.location.href = service.Linekefu
		}
		// this.$router.push("ServicePage");
		},
	toServicePage_WhatsApp(){
		const service = this.$store.getters.getBaseInfo;
		console.log(service)
		if (service.iskefu == 1){
		console.log('ssss')
		window.location.href = service.WhatsAppkefu
		}
		// this.$router.push("ServicePage");
		},
	toServicePage_Telegram(){
		const service = this.$store.getters.getBaseInfo;
		console.log(service)
		if (service.iskefu == 1){
		console.log('ssss')
		window.location.href = service.Telegramkefu
		}
		// this.$router.push("ServicePage");
		}				
	}
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.servicelistItem{
  display: flex;
  flex-direction: column;
  height: 200px;
  padding: 30px 30px;
  margin: 30px 20px;
  border-radius: 20px;
  justify-content: space-between;
  background: #fff;
}
.servicelistItem .servicelistItemTop{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100px;
}
.servicelistItem .servicelistItemTop .servicelistItemImage{
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.servicelistItem .servicelistItemTop .servicelistItemText{
  margin-left: 50px;
  font-size: 43px;
  font-weight: 700;
  color: #000;
  flex: 1;
}
.servicelistItem .servicelistItemTop .servicelistItemBtn{
  display: flex;
  width: 150px;
  height: 55px;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, rgba(223, 53, 173, 0.8), rgba(79, 29, 181, 0.8));
}
.servicelistItem .servicelistItemTop .servicelistItemBtn .servicelistItemBtnText{
  color: #fff;
  font-size: 25px;
}
.servicelistItem .servicelistItemBottom{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background: #f2f2f5;
  border-radius: 10px;
  color: #979799;
}
.servicelistItem .servicelistItemBottom .servicelistItemInfoText{
  font-size: 25px;
}
</style>
