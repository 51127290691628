var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container page"},[_c('van-nav-bar',{staticClass:"nav-bar",attrs:{"title":_vm.$t('my.task_record')},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('van-icon',{attrs:{"name":"arrow-left","color":"#fff"},on:{"click":function($event){return _vm.back()}}})]},proxy:true}])}),_c('div',{staticClass:"main"},[_c('van-pull-refresh',{on:{"refresh":_vm.onRefresh},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}},[(_vm.list.length === 0)?_c('van-empty',{attrs:{"description":_vm.$t('withdraw.empty_data')}}):_vm._l((_vm.list),function(v,key){return _c('div',{key:key,staticClass:"item_list"},[_c('div',{staticClass:"lottery_info"},[_c('van-image',{staticClass:"cover",attrs:{"src":v.ico},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('van-loading',{attrs:{"type":"spinner"}})]},proxy:true}],null,true)}),_c('span',{staticClass:"period-number"},[_vm._v(_vm._s(v.expect))]),_c('span',{staticClass:"period-number"},[_vm._v(_vm._s(v.name))])],1),_c('div',{staticClass:"recent"},[_c('div',{staticClass:"kuaisan-ball left"},[_c('span',{staticClass:"ressquareBox",staticStyle:{"margin-left":"10vw"}},[_c('img',{staticStyle:{"width":"7vw"},attrs:{"src":v.status === 0 ? 'img/lottery/0.png' : 
                        ((v.opencode[0] + v.opencode[1] + v.opencode[2]) >= 11 && 
                        (v.opencode[0] + v.opencode[1] + v.opencode[2]) <= 18 ? 
                        _vm.fourseasons_ico : _vm.hilton_ico),"alt":"Status Image"}})]),_c('span',{staticClass:"ressquareBox"},[_c('img',{staticStyle:{"width":"7vw"},attrs:{"src":v.status === 0 ? 'img/lottery/0.png' : 
                        (v.opencode[0] + v.opencode[1] + v.opencode[2]) % 2 === 0 ? 
                        _vm.shangrila_ico : _vm.hyatt_ico,"alt":"Even/Odd Image"}})])])]),_c('div',{staticClass:"topInfo"},[(v.status === 1)?_c('span',{staticStyle:{"color":"#07c160"}},[_vm._v(_vm._s(v.status_text))]):_c('span',[_vm._v(_vm._s(v.status_text))]),_c('span',[_vm._v(_vm._s(_vm.$t("reservation.money"))+"："+_vm._s(v.money))])]),_c('div',{staticClass:"topInfo"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("reservation.curr_choose"))+"： "),_c('img',{staticStyle:{"width":"7vw"},attrs:{"src":v.type === 'bi' ? _vm.fourseasons_ico : 
                       (v.type === 'sm' ? _vm.hilton_ico : 
                       (v.type === 'si' ? _vm.hyatt_ico : 
                       (v.type === 'do' ? _vm.shangrila_ico : ''))),"alt":"Current Choice Image"}})]),_c('span')]),_c('div',{staticClass:"topInfo"},[(v.is_win === 1)?_c('span',{staticStyle:{"color":"#07c160"}},[_vm._v(_vm._s(_vm.$t("reservation.win")))]):(v.status === 1)?_c('span',[_vm._v(_vm._s(_vm.$t("reservation.lose")))]):_vm._e()]),_c('div',{staticClass:"time"},[_c('span',[_vm._v(_vm._s(_vm.$t("reservation.order_time"))+"："+_vm._s(v.create_time))])]),_c('div',{staticClass:"time"},[_c('span',[_vm._v(_vm._s(_vm.$t("reservation.settle_time"))+"："+_vm._s(v.update_time))])])])})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }