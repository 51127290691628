<template>
    <div class="container page">
        <van-nav-bar :title="$t('concubine.concubine')" class="nav-bar">
            <!-- <template #left>
                <van-icon name="arrow-left" color="#fff" @click="back()" />
            </template> -->
        </van-nav-bar>
        <div  class="banner">
          <div  class="van-swipe">
            <div class="van-swipe__track" style="transition-duration: 0ms; transform: translateX(0px); width: 366px;">
              <div  class="van-swipe-item" style="width: 366px;">
                <div  class="van-image" style="width: 100vw; height: 200px;">
                  <img class="van-image__img" :src="getBanner" style="object-fit: cover;">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
            <div class="van-list">
            <div class="pblBox">
            <van-pull-refresh border="false" class="list-wrapper" v-model="isLoading" @refresh="onRefresh">
                <van-grid :column-num="2" :gutter="10">
                    
                    <!-- <van-grid-item @click="profile(v.id)" v-for="(v, k) in datalist" :key="k">
                        <van-image class="game_item_img" :src="v.img_url">
                            <template v-slot:loading>
                                <van-loading type="circular" />
                            </template>
                        </van-image>
                        <span class="rig-name">{{ v.xuanfei_name }}</span>
                    </van-grid-item> -->
<!-- ////////////////////// -->
        <van-grid-item @click="profile(v.id)" v-for="(v, k) in datalist" :key="k">
            <div  style=" margin-right: 2vw;">
              <div  class="releaseItem">
                <div class="imgBox">
                  <div class="van-image" style="width: 100%; height: 100%;">
                    <img :src="v.img_url" class="van-image__img">
                  </div>
                  <span><i >{{$t('concubine.gaoduan')}}</i></span>
                  <div class="infoBox">
                    <i class="van-icon van-icon-location" style="font-size: 14px;"></i>
                    <span style="font-size: 12px; margin: 0px 0px 0px 2px;"></span>
                    <div class="wantyuebtn">{{$t('concubine.my_reservation')}}</div>
                  </div>
                </div>
                <div class="tagBox" style="color: rgb(102, 102, 102);"></div> 
                <div class="di">
                  <div >
                    <span style="display: inline-block; margin-right: 2px; width: 12px; height: 12px; border-radius: 50%; background: rgb(239, 186, 143);"></span>
                    <span >{{ v.xuanfei_name }}</span>
                  </div>
                  <div >
                    <i class="van-icon van-icon-like" style="color: rgb(220, 99, 161);"></i>
                    <span >{{ v.dianzan }}</span>
                  </div>
                </div>
              </div>
            </div>                    
            </van-grid-item>        
            
                    
<!-- ////////////////////// -->
                </van-grid>
            </van-pull-refresh>
        
        
        </div>
        </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            vod_name: '北京',
            isLoading: false,
            getBanner:[],
            datalist: [
                // {
                // 	xuanfei_name: '北京 健身达人',
                // 	img_url: 'https://kk.betman2.co/storage/41/62fe1bd26d01c_image_2022-08-18_190032731.png'
                // },
                // {
                // 	xuanfei_name: '北京 学生妹',
                // 	img_url: 'https://kk.betman2.co/storage/344/630869b4bd2d6_1.jpg'
                // }
            ]
        };
    },
    methods: {
        back() {
            this.$router.push({ path: 'Choose' });
        },
        onRefresh() {
            setTimeout(() => {
                this.$toast(this.$t("reservation.refresh"));
                this.isLoading = false;
            }, 500);
        },
        getBasicConfig(){
          this.$http({
            method: 'get',
            url: 'sys_config'
          }).then(res=>{
            this.getBanner= res.data.banners[0].url;//获取banner
          })
        
        },
        profile(id) {
            this.$router.push({ path: '/profile?id=' + id + '&name=' + this.vod_name + '&adsid=' + this.$route.query.id });
        },
        getxuanfeilist() {
            this.$http({
                method: 'get',
                url: 'xuanfeilist',
                data: { id: 1}
                //data: { id: this.$route.query.id }
            }).then(res => {
                this.datalist = res.data;
            });
        }
    },
    created() {
        this.vod_name = this.$route.query.name;
        this.getxuanfeilist();
        this.getBasicConfig();
    }
};
</script>

<style scoped>
.right {
    margin-top: 10px;
}
::v-deep .van-grid-item__content--center {
    /* border-radius: 15px;
    padding: 0;
    height: auto; */
}
::v-deep .van-image__img {
    border-radius: 10px;
    /* padding: 15px; */
    object-fit: cover;
}
.van-grid-item__content {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
    padding: 0.1vw 0.1vw;
    background-color: #ffffff00;
    border-radius: 2.067vw;
}
.rig-name {
    width: 100%;
    height: 60px;
    line-height: 60px;
    margin-top: 10px;
    background-color: #f7f7f7;
    border-radius: 0 0 15px 15px;
    font-size: 15px;
    padding-left: 10px;
}
.nav-bar{
    background: linear-gradient(30deg, #7d76ef, #d63f8c);
}
.releaseItem{
    width: 100%;
    margin-bottom: 15px;
}
.releaseItem .imgBox{
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    height: 67vw;
}
.van-image {
    position: relative;
    display: inline-block;
}
.van-image__error, .van-image__img, .van-image__loading {
    display: block;
    width: 100%;
    height: 100%;
}
.releaseItem .imgBox>span{
    position: absolute;
    z-index: 9;
    background-color: #ebcaaf;
    color: #8d684b;
    transform: rotate(45deg);
    width: 20vw;
    height: 20vw;
    top: -11vw;
    right: -11vw;
}
.releaseItem .imgBox>span>i{
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 700;
    font-size: 4vw;
}
.releaseItem .imgBox .infoBox{
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,.4);
    display: flex;
    align-items: center;
    color: #fff;
    width: 100%;
    height: 8vw;
    padding: 0 10px;
}
.van-icon, .van-icon:before {
    display: inline-block;
}
.van-icon {
    position: relative;
    font: normal normal normal 14px/1 vant-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}
.releaseItem .imgBox .infoBox .wantyuebtn {
    border: 1px solid #fff;
    padding: 5px 8px;
    border-radius: 8px;
    opacity: .9;
    font-size: 3vw;
    margin-left: auto;
}
.releaseItem .tagBox {
    font-size: 3vw;
    margin: 5px 0;
}
.releaseItem .di {
    display: flex;
    justify-content: space-between;
    font-size: 3vw;
    color: #666;
}
.pblBox{
    display: flex;
    justify-content: space-between;
    padding: 1px 2px;
    flex-wrap: wrap;
}
.van-swipe {
    position: relative;
    overflow: hidden;
    cursor: grab;
    -webkit-user-select: none;
    user-select: none;
}
.van-swipe__track {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    height: 100%;
}
.van-swipe-item {
    position: relative;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
}

</style>
