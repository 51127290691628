<template>
  <div class="container page">
    <van-nav-bar :title="$t('my.task_record')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
    </van-nav-bar>
    <div class="main">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <van-empty v-if="list.length === 0" :description="$t('withdraw.empty_data')" />
        <div v-else class="item_list" v-for="(v,key) in list" :key="key">
          <div class="lottery_info">
            <van-image class="cover" :src="v.ico">
              <template v-slot:loading>
                <van-loading type="spinner"/>
              </template>
            </van-image>
            <span class="period-number">{{v.expect}}</span>
            <span class="period-number">{{v.name}}</span>
          </div>
          <div class="recent">
            <div class="kuaisan-ball left">
              <!-- <van-image class="res-img" :src=" v.status === 0 ? 'img/lottery/open_num.gif' : 'img/lottery/shaizi/' + v.opencode[0] + '.png' " >
                <template v-slot:loading>
                  <van-loading type="spinner"/>
                </template>
              </van-image>
              <van-image class="res-img" :src=" v.status === 0 ? 'img/lottery/open_num.gif' : 'img/lottery/shaizi/' + v.opencode[1] + '.png' " >
                <template v-slot:loading>
                  <van-loading type="spinner"/>
                </template>
              </van-image>
              <van-image class="res-img" :src=" v.status === 0 ? 'img/lottery/open_num.gif' : 'img/lottery/shaizi/' + v.opencode[2] + '.png' " >
                <template v-slot:loading>
                  <van-loading type="spinner"/>
                </template>
              </van-image> 
               <span class="res-des middle">{{v.status === 0 ? 0 : v.opencode[0] + v.opencode[1] + v.opencode[2]}}</span> -->
             <!-- <span class="ressquareBox" style="margin-left: 10vw;">{{v.status === 0 ? 0 : (v.opencode[0] + v.opencode[1] + v.opencode[2]) >= 11 && (v.opencode[0] + v.opencode[1] + v.opencode[2]) &lt;= 18 ? $t("reservation.big") : $t("reservation.small")}}</span>
             <span class="ressquareBox">{{v.status === 0 ? 0 : (v.opencode[0] + v.opencode[1] + v.opencode[2]) % 2 === 0 ? $t("reservation.double") : $t("reservation.single")}}</span> -->
             <span class="ressquareBox" style="margin-left: 10vw;">
               <img 
                 :src="v.status === 0 ? 'img/lottery/0.png' : 
                        ((v.opencode[0] + v.opencode[1] + v.opencode[2]) >= 11 && 
                        (v.opencode[0] + v.opencode[1] + v.opencode[2]) <= 18 ? 
                        fourseasons_ico : hilton_ico)" 
                 alt="Status Image" style="width: 7vw;">
             </span>
             <span class="ressquareBox">
               <img 
                 :src="v.status === 0 ? 'img/lottery/0.png' : 
                        (v.opencode[0] + v.opencode[1] + v.opencode[2]) % 2 === 0 ? 
                        shangrila_ico : hyatt_ico" 
                 alt="Even/Odd Image" style="width: 7vw;">
             </span>

           
			</div>
          </div>
          <div class="topInfo">
            <span v-if="v.status === 1" style="color: #07c160">{{v.status_text}}</span>
            <span v-else>{{v.status_text}}</span>
            <span>{{$t("reservation.money")}}：{{v.money}}</span>
          </div>
           <div class="topInfo" >
            <!-- <span>{{$t("reservation.curr_choose")}}：{{ v.type === 'bi'? $t("reservation.big") : (v.type ==='sm'? $t("reservation.small") : (v.type ==='si'? $t("reservation.single") : (v.type === 'do'? $t("reservation.double") : v.type))) }}</span> --><!--  -->
            <span>
              {{ $t("reservation.curr_choose") }}：
              <img 
                :src="v.type === 'bi' ? fourseasons_ico : 
                       (v.type === 'sm' ? hilton_ico : 
                       (v.type === 'si' ? hyatt_ico : 
                       (v.type === 'do' ? shangrila_ico : ''))) " 
                alt="Current Choice Image" style="width: 7vw;">
            </span>

            <span></span>
          </div> 
		<div class="topInfo">
			<span v-if="v.is_win === 1" style="color: #07c160">{{$t("reservation.win")}}</span>
			<span v-else-if="v.status === 1">{{$t("reservation.lose")}}</span>            
			</div> 
          <div class="time">
            <span>{{$t("reservation.order_time")}}：{{v.create_time}}</span>
          </div>
          <div class="time">
            <span>{{$t("reservation.settle_time")}}：{{v.update_time}}</span>
          </div>
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      list:[],
      fourseasons_ico:"img/lottery/fourseasons_ico.png",
      hilton_ico:"img/lottery/hilton_ico.png",
      hyatt_ico:"img/lottery/hyatt_ico.png",
      shangrila_ico:"img/lottery/shangrila_ico.png",
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    onRefresh() {
      setTimeout(() => {
        this.$toast(this.$t("reservation.refresh"));
        this.isLoading = false;
      }, 500);
    },
    getUserGameList(){
      this.$http({
        method: 'get',
        url: 'user_get_game_list'
      }).then(res=>{
        if(res.code === 200){
			console.log(res.data)
          this.list = res.data;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    }

  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserGameList();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
  color: #000000;
  font-size: 35px;
}

::v-deep .van-loading__text {
  color: #000000;
  font-size: 35px;
}
.container .main{
  position: relative;
  overflow: auto;
  background-color: #f2f2f5;
  height: 100%;
  padding: 0 10px;
}
.item_list{
  padding: 15px 15px;
  margin: 30px 10px;
  background: #fff;
  border-radius: 10px;
  line-height: 60px;
}

.item_list .topInfo span{
  flex: 1;
  font-size: 30px;
  font-weight: 700;
  color: #ff253f;
}
.item_list .time span{
  flex: 1;
  font-size: 25px;
  font-weight: 500;
  color: #000;
}

.item_list .topInfo span:last-child{
  float: right;
}
.item_list .desc span{
  font-size: 25px;
  font-weight: 700;
  color: #9b9b9b;
}
.item_list .cover{
  width: 60px;
  height: 60px;
  -o-object-fit: cover;
  object-fit: cover;
}
.item_list  .period-number{
  margin-left: 50px;
  margin-right: 10px;
  height: 50px;
  line-height: 60px;
  font-size: 35px;
  font-weight: 700;
  color: #000;
}
.item_list .lottery_info{
  display: flex;
}
.recent {
  display: flex;
  align-items: center;
  height: 100px;
}
.kuaisan-ball .left{
  justify-content: flex-start;
}
.kuaisan-ball{
  flex: 1;
  display: flex;
  align-items: center;
}

.kuaisan-ball .res-img{
  width: 70px;
  height: 70px;
  margin-right: 30px;
}
.kuaisan-ball .res-des{
  font-weight: 700;
  text-align: center;
  color: #000;
}
.kuaisan-ball .res-des.middle{
  width: 15%;
  font-size: 35px;
}
.ressquareBox {
    border-radius: 8px;
    padding: 0 3vw;
    /* background: linear-gradient(90deg, #f560cd, #4f2b8a); */
    background: linear-gradient(90deg, #fffcff, #ffffff);
    color: #fff;
    height: 7vw;
    line-height: 7vw;
    text-align: center;
    font-size: 3vw;
    font-weight: 700;
    margin-right: 15px;
    box-shadow: 1px 1px 2.0vw rgb(58 8 69 / 11%);
}
.nav-bar{
	background: linear-gradient(90deg, #f560cd, #4f2b8a);
}
</style>
