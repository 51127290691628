<template>
  <div class="movie-hall page">
    <van-nav-bar
        class="nav-bar"
        :title="$t('video.video')"
    />
   <!-- <van-tabs v-model="active" animated swipeable  @change="OnChange" >
      <van-tab v-for="(v,key) in videolitem" :key="key" :title="v.name" :name="v.key" > </van-tab>
    </van-tabs> -->
    <swiper class="video_swiper" ref="swiper" :options="videoSwiperOption" @slideChange="itemChange">
      <swiper-slide v-for="(v,key) in videolitem" :key="key" >
        <div class="movie-list-tab">
           <van-pull-refresh v-model="isLoading" @refresh="onRefresh"> 
            <div class="hot-recommend-div">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    :immediate-check="false"
                    :finished-text="$t('video.no_more')"
                    @load="onLoad"
                >
                  <div class="list-item">
                    <div class="movie-list-item" v-for="(v,key) in videolist" :key="key" @click="toPlayVideo_buy(v.id)">
                      <van-image class="cover_img"  round :src="v.vod_pic" style="object-fit: cover;">
                        <template v-slot:loading>
                          <van-loading type="circular"/>
                        </template>
                        <span><i >{{$t('video.tip')}}</i></span>
                      </van-image>
                      
                      <div class="movie-list-item-bottom">
                        <div class="movie-time-div">
                          <span>{{v.vod_name}}</span>
                          <span style="display: none;">{{$t("video.play")}}:{{v.count}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="padding: 3vw 3vw 3vw 3vw; display: flex;justify-content: center;align-items: center;">
                      <p style="padding: 7vw 7vw 7vw 7vw;border-radius: 2vw;background-color: rgb(193 85 189 / 51%); font-weight: bold;text-shadow: 0 0 10px #fff; /* 水平偏移 垂直偏移 模糊半径 颜色 */ width: 80vw;display: flex;justify-content: center;align-items: center; height: 10vw;"> {{$t("video.tip1")}}
                      </p></div>
                </van-list>

            </div>
          </van-pull-refresh> 
          
        </div>
        
      </swiper-slide>
    </swiper>
    
  </div>
</template>

<script>
import { Toast } from 'vant';
export default {
  data() {
    return {
      active: 0,
      isLoading: false,
      count:0,
      loading: false,
      finished: false,
      refreshing: false,
      videolitem: [],
      videolist: [],
      number:0,
      page:0,
      videoSwiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        slidesPerGroup : 1,
      }
    };
  },
  methods: {
    getVideoClass(){
      this.$http({
        method: 'get',
        url: 'video_class'
      }).then(res=>{
        this.videolitem = res.data;
      })
    },
    toPlayVideo(id){
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {
        this.$router.push({path:'/PlayVideo?id='+id})
      }
    },
    toPlayVideo_buy(){
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {
        this.$toast(this.$t("video.buy"));
      }
    },
    itemChange(){
      this.active = this.$refs.swiper.swiper.activeIndex
      this.OnChange()
    },
    getVideoList(){
      this.$http({
        method: 'get',
        data:{id:this.active,page:this.page}, 
        url: 'video_list'
      }).then(res=>{
        this.videolist = this.videolist.concat(res.data.data);
        this.count = res.data.count;
        this.page++;

      })
    },
    onLoad() {
        this.getVideoList();
      let timer = setTimeout(() => {
        if (this.refreshing) {
          this.videolist = [];
          this.refreshing = false;
        }
        this.loading = false;
        if (this.videolist.length === this.count) {
          this.finished = true;
        }
        this.finished && clearTimeout(timer);//清除计时器
      }, 500);
    },
     OnChange(){
      this.videolist = [];
      this.number = 0;
      this.page = 1;
      this.count = 0;
      this.getVideoList();//获取视频列表

    },
    onRefresh() {
      setTimeout(() => {
        this.finished = false;
        this.loading = true;
        this.onLoad();
        this.isLoading = false;
        Toast(this.$t("reservation.refresh"));
      }, 500);
    },
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getVideoClass();//获取视频类目
      this.OnChange()
    }
  }
};
</script>

<style lang='less' scoped>
.page{
  position: absolute!important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f2f2f5;
}
.newnavbar{
    height: 13vw;
    background: linear-gradient(30deg,#7d76ef,#d63f8c);
}
.van-nav-bar {
  line-height: 50px;
}

::v-deep .van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  color: #ffffff;
  font-size: 35px;
}
::v-deep .van-nav-bar__content {
  height: 100px;
}

.movie-hall{
  display: flex;
  flex-direction: column;
  bottom: 100px;
  background: #f2f2f5;
}
::v-deep .van-tabs__nav {
 /* background: linear-gradient(to right, rgb(126, 86, 120), rgb(230, 195, 161)); */
 background: #ffffff;
}
 ::v-deep .van-tab {
  /* color: rgb(197, 113, 207); */
  color:rgb(68, 40, 137);
  font-size: 30px;
  line-height: 5vw;
  font-weight: bold;
} 
::v-deep .van-tab.van-tab--active {
    color: rgb(197, 113, 207);
    font-size: 30px;
    line-height: 5vw;
    font-weight: bold;
  
}
.special-style {
    color: rgb(197, 113, 207);
    font-weight: bold;
    background: url("/img/new.png") right top 5px / 30% no-repeat;
}
::v-deep .van-tabs__line {
  bottom: 15px;
  width: 55px;
  height: 7px;
  border-radius: 0px;
  background-color: #ffffff;
}
::v-deep .van-tabs--line .van-tabs__wrap {
  height: 100px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
  padding: 0 23px;
}
::v-deep  .van-hairline--bottom::after {
  border-bottom-width: 0px;
}
.video_swiper {
  width: 100%;
  flex: 1;
  .swiper-slide {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 100%;
    justify-content: center;
    height: 100%;
    position: relative;
    transition-property: transform;
  }
}
.movie-list-tab {
  overflow: auto;
  height: 100%;
}
::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
  color: #000;
  font-size: 35px;
}
.movie-list-tab .hot-recommend-div{
  height: 100%;
  margin: 10px auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  //overflow: auto;
}
.list-item{
  display: flex;
  width: calc(100% - 50px);
  margin: 10px auto;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.list-item .movie-list-item:nth-child(odd) {
  margin-right: 20px;
}
.movie-list-item .cover_img{
  border-radius: 20px;
  width:335px;
  height:450px;
}
.movie-list-item{
  margin-bottom: -10px;
}
.list-item .movie-list-item-bottom{
  position: relative;
  width: 335px;
  bottom: 9vw;
}
.list-item .movie-list-item-bottom .movie-time-div{
  background-color: rgba(0,0,0,.4);
}
.list-item .movie-list-item-bottom>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.list-item .movie-list-item-bottom .movie-time-div .van-count-down {
  color: #fff;
}
.list-item .movie-list-item .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 180px;
  padding-left: 8px;
  font-size: 25px;
}
.list-item .movie-time-div {
  color: #fff;
  border-radius: 0 0 20px 20px;
  height: 8vw;
  font-size: 3vw;
  padding-right: 1vw;
}
.my-tab{
    
}
.van-tab {
    color: #000000;
    font-size: 4vw;
}
.movie-list-item .cover_img>span{
    position: absolute;
    z-index: 9;
    background-color: #ebcaaf;
    color: #8d684b;
    transform: rotate(45deg);
    width: 40vw;
    height: 12vw;
    top: 3vw;
    right: -10vw;
    box-shadow: 1px 1px 10px rgb(0 0 0 / 40%);
}
.movie-list-item .cover_img>span>i {
    position: absolute;
    bottom: 0.667vw;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 700;
    font-size: 4vw;
}
::v-deep .van-image__img{
    object-fit: cover;
}
</style>
