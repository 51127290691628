<template>
  <div class="bg-container page">
    <img class="bg-img" src="img/login/login.png" v-if="showImage">
      <video class="bg-video" autoplay muted loop v-if="!showImage">
        <source src="img/login/movie.mp4" type="video/mp4">
        Your browser does not support the video tag.
      </video>
    <div class="bg-wrapper">
      <div class="login">
        <van-nav-bar class="nav-bar">
          <template #left>
            <van-icon name="arrow-left" color="#fff" @click="back()" />
          </template>
          <template #right>
            <div class="language" @click="$router.push('/language')">
              <!-- <img :src="require('../images/language/' + lang + '.png')" /> -->
                {{ $t("setting.language") }}
            </div>
          </template>
        </van-nav-bar>
        <div class="wrapper">
         <!-- <div class="logo-container">
            <div class="logo-wrapper">
              <img
                class="logo-img"
                :src="
                  this.$store.getters.getBaseInfo.ico !== undefined
                    ? this.$store.getters.getBaseInfo.ico
                    : '/img/null.png'
                "
              />
            </div>
          </div> -->
          <div class="title">{{ $t("auth.login") }}</div>
          <div class="loginForm">
            <van-field
              v-model="username"
              clearable
              input-align="center"
              class="input"
              :placeholder="$t('auth.username_place')"
            />
            <van-field
              v-model="password"
              :type="passwordType"
              input-align="center"
              class="input"
              :placeholder="$t('auth.pwd_place')"
            >
              <template slot="right-icon">
                <van-icon
                  :name="passwordType === 'password' ? 'closed-eye' : 'eye-o'"
                  @click="switchPasswordType"
                />
              </template>
            </van-field>
            <div class="reset-text" @click="toService()">
              <span>{{$t("auth.forgetpwd")}}?</span>
            </div>
            <div @click="toRegister()" class="register-text">
              <span>{{$t("auth.no_account")}}</span>
            </div>
            <van-button
              class="login-btn"
              type="primary"
              size="normal"
              @click="doLogin()"
              >{{$t("auth.login")}}</van-button
            >
          </div>
        <div class="menu-item" @click="toService()">
        <van-image class="menu-item-icon" src="img/mine/kefu_1.svg">
            <template v-slot:loading>
            <van-loading type="spinner"/>
            </template>
            </van-image>
            <span class="menu-item-label" >{{$t("my.online_service")}}</span>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: "inputValue",
    event: "input",
  },
  props: {
    /**
     * 当前输入的值
     */
    inputValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      username: "",
      lang: 'jp_jp',
      password: this.inputValue,
      passwordType: "password",
      showImage: false // 设置为 false 时显示视频，true 时显示图片
    };
  },
  mounted(){
    localStorage.setItem("lang", 'jp_jp');
    this.lang = localStorage.getItem("lang") || 'jp_jp';
  },
  methods: {
    switchPasswordType() {
      this.passwordType =
        this.passwordType === "password" ? "text" : "password";
    },
    back() {
      return window.history.back();
    },
    toRegister() {
      this.$router.push("Register");
    },
    doLogin() {
      if (
        this.username === "" ||
        this.username === null ||
        this.username === undefined
      ) {
        this.$toast(this.$t("auth.username_place"));
        return false;
      }
      if (
        this.password === "" ||
        this.password === null ||
        this.password === undefined
      ) {
        this.$toast(this.$t("auth.pwd_place"));
        return false;
      }
      this.$http({
        url: "member_login",
        method: "post",
        data: { 
          username: this.username, 
          password: this.password,
          lang: this.lang
        },
      }).then((res) => {
        if (res.code === 200) {
          this.$toast.success(res.msg);
          localStorage.setItem("token", res.data.id);
          this.$router.push("Mine");
        } else {
          this.$toast(res.msg);
        }
      });
    },
    toService(){
    if(this.$store.getters.getBaseInfo.iskefu == 1){
        this.$router.push("ServiceOnline");
        }else {
        this.$toast.fail(this.$t("setting.forbid"));
        }
    },
    },
  created() {
    if (localStorage.getItem("token")) {
      return window.history.back();
    }
  },
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.login {
  height: 100%;
}
.bg-container .bg-wrapper .login .nav-bar {
  background: 0 0;
}

.language{
        position: static;
        top: 4px;
        font-size: 5vw;
        color:#ffffff87;
        right: 0;
        height: 80px;
        img{
            height: 100%;
        }
        
    }

.login .wrapper {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.logo-container {
  margin: 0 auto;
  width: 45%;
}
.logo-container .logo-wrapper {
  position: relative;
  padding-bottom: 62.5%;
}
.logo-container .logo-wrapper .logo-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.login .wrapper .title {
  line-height: 100px;
  text-align: center;
  font-size: 45px;
  font-weight: 700;
  color: #fff;
  letter-spacing: 5px;
}
.login .wrapper .loginForm {
  padding: 60px;
}
.login .wrapper .loginForm .input {
  padding: 10px 20px;
  margin-top: 40px;
  border-radius: 50px;
  text-align: center;
  line-height: 80px;
  font-size: 30px;
  color: #4e4e4e;
}
::v-deep .van-field__right-icon .van-icon {
  font-size: 50px;
}
::v-deep .van-icon {
  font-size: 50px;
}
.login .wrapper .loginForm .reset-text {
  margin: 30px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.login .wrapper .loginForm .reset-text span {
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  line-height: 15px;
}
.login .wrapper .loginForm .register-text {
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login .wrapper .loginForm .register-text span {
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  line-height: 20px;
}
.login .wrapper .loginForm .active {
}
.login .wrapper .loginForm .login-btn {
  margin-top: 85px;
  width: 100%;
  height: 100px;
  border-radius: 50px;
  color: #fff;
  background: linear-gradient(90deg, #6529c9, #cc2996);
  font-size: 30px;
  font-weight: bolder;
  border: none;
}

.menu-item{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 75%;
  height: 130px;
  padding: 0 0 0 20vw;
}
.menu-item .menu-item-label{
  font-size: 30px;
  color: #ffffff;
  font-weight: 500;
}
.menu-item .menu-item-icon{
  margin: 25px;
  width: 60px;
  height: 60px;
  -o-object-fit: contain;
  object-fit: contain;
  background-color: #ffffff;
  border-radius: 50%;
}
.bg-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.bg-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
